<template>
  <a-modal
    title="新建购销合同"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="this.ok"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="编号">
              <a-input v-decorator="['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="项目" v-if="false">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'project_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入项目"
                :default-active-first-option="false"
                @search="this.projectSearch"
                @change="handleProjectChange"
              >
                <a-select-option v-for="d in projectList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="客户">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'client_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入客户"
                :default-active-first-option="false"
                @search="this.clientSearch"
                @change="handleClientChange"
              >
                <a-select-option v-for="d in clientList" :key="d.client_id" :tt="d.client">
                  {{ d.client }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="已存档" v-if="false">
              <a-switch v-decorator="['is_archived', { initialValue: false, valuePropName: 'checked' }]" />
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea :rows="4" v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="合同关键点">
              <a-button icon="plus" @click="handleAddKeyPoint">新建</a-button>
              <a-table
                bordered
                :data-source="otherData.key_point"
                :columns="keyPointColumns"
                v-if="this.otherData.key_point.length > 0"
                :pagination="false"
                key="keys">
                <template slot="action" slot-scope="text, record">
                  <a-popconfirm
                    v-if="otherData.key_point.length"
                    title="确定删除?"
                    @confirm="() => onKeyPointDelete(record.keys)"
                  >
                    <a href="javascript:;">删除</a>
                  </a-popconfirm>
                </template>
              </a-table>
            </a-form-item>
            <a-form-item label="合同附件">
              <a-button icon="plus" @click="handleAddAttachment">新建</a-button>
              <a-table
                bordered
                :data-source="otherData.attachment"
                :columns="attachmentColumns"
                v-if="this.otherData.attachment.length > 0"
                :pagination="false"
                key="key">
                <template slot="action" slot-scope="text, record">
                  <a-popconfirm
                    v-if="otherData.attachment.length"
                    title="确定删除?"
                    @confirm="() => onAttachmentDelete(record.key)"
                  >
                    <a href="javascript:;">删除</a>
                  </a-popconfirm>
                </template>
              </a-table>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="开启审批">
              <a-switch v-decorator="['is_approval_needed', { initialValue: true, valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <component
        :ref="currentComponent"
        @cancel="handleAddCancel"
        @ok="handleOk"
        :record="mdl"
        :is="currentComponent"
        :visible="visibleFrom"
        v-if="visibleFrom"
        :loading="confirmLoadingFrom"
        :model="mdl"></component>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { project_list } from '@/api/trade_project'
import KeyPointCreateForm from '../keypoint/modules/CreateForm'
import AttachmentCreateForm from '../attachment/modules/CreateForm'
import { project_client_list } from '@/api/trade_project_client'
// 表单字段
const fields = ['id', 'host_corporation_id', 'category_id', 'number', 'name', 'credit', 'cash']
export default {
  components: {
    KeyPointCreateForm,
    AttachmentCreateForm
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.projectSearch = debounce(this.handleProjectSearch, 800)
    this.clientSearch = debounce(this.handleClientSearch, 800)
    return {
      projectList: [],
      clientList: [],
      currentComponent: '',
      visibleFrom: false,
      confirmLoadingFrom: false,
      mdl: {},
      form: this.$form.createForm(this),
      otherData: {
        key_point: [],
        attachment: []
      },
      keyPointColumns: [
        {
          title: '名称',
          dataIndex: 'key',
          scopedSlots: { customRender: 'key' }
        },
        {
          title: '内容',
          dataIndex: 'value',
          scopedSlots: { customRender: 'value' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      attachmentColumns: [
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '附件',
          dataIndex: 'url'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    // this.$watch('model', () => {
    //   console.log('custom modal created', this.model)
    //   this.model && this.form.setFieldsValue(pick(this.model, fields))
    // })
    this.model && this.form.setFieldsValue(pick(this.model, fields))
    this.handleProjectSearch(undefined)
    this.handleClientSearch(undefined)
  },
  methods: {
    handleProjectSearch (value) {
      project_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.projectList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleProjectChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleProjectSearch(value)
      }
    },
    handleClientSearch (value) {
      project_client_list({ name: value, project_id: this.$route.params.project_id }).then((res) => {
        const result = res.data.entries || []
        this.clientList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleClientChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleClientSearch(value)
      }
    },
    handleAddKeyPoint () {
      this.currentComponent = 'KeyPointCreateForm'
      this.visibleFrom = true
    },
    onKeyPointDelete (key) {
      const dataSource = [...this.otherData.key_point]
      this.otherData.key_point = dataSource.filter(item => item.keys !== key)
    },
    onAttachmentDelete (key) {
      const dataSource = [...this.otherData.attachment]
      this.otherData.attachment = dataSource.filter(item => item.key !== key)
    },
    handleAddAttachment () {
      this.currentComponent = 'AttachmentCreateForm'
      this.visibleFrom = true
    },
    handleAddCancel () {
      this.visibleFrom = false
      const form = this.$refs[this.currentComponent].form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs[this.currentComponent].form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          if (this.currentComponent === 'KeyPointCreateForm') {
            const current_date = new Date()
            values.keys = current_date.getHours() + '' + current_date.getMinutes() + '' + current_date.getSeconds()
            this.otherData.key_point.push(values)
          } else if (this.currentComponent === 'AttachmentCreateForm') {
            values.url = values.url.fileList[0].url
            const current_date = new Date()
            values.key = current_date.getHours() + '' + current_date.getMinutes() + '' + current_date.getSeconds()
            this.otherData.attachment.push(values)
          }
          this.visibleFrom = false
          form.resetFields()
        } else {
          this.confirmLoading = false
        }
      })
    },
    ok () {
      if (this.otherData.key_point) {
        this.otherData.key_point = this.otherData.key_point.map(item => {
          item.keys = undefined
          console.log(item)
          return item
        })
        console.log(this.otherData.key_point)
      }
      if (this.otherData.attachment) {
        this.otherData.attachment = this.otherData.attachment.map(item => {
          item.key = undefined
          console.log(item)
          return item
        })
        console.log(this.otherData.attachment)
      }
      console.log(this.otherData)
      this.$emit('ok', this.otherData)
    }
  }
}
</script>
<style scoped>

.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
